"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var pad_string_1 = require("./pad-string");
function isBuffer(input) {
  return typeof Buffer !== 'undefined' && Buffer.isBuffer(input);
}
function bufferFromString(string, encoding) {
  if (typeof Buffer !== 'undefined') {
    return Buffer.from(string, encoding);
  } else {
    var encoder = new TextEncoder();
    return encoder.encode(string);
  }
}
function stringFromBuffer(buffer, encoding) {
  if (typeof Buffer !== 'undefined' && buffer instanceof Buffer) {
    return buffer.toString(encoding);
  } else {
    var decoder = new TextDecoder(encoding);
    return decoder.decode(buffer);
  }
}
function toBuffer(base64url) {
  if (typeof Buffer !== 'undefined') {
    return Buffer.from(toBase64(base64url), 'base64');
  }
  var base64 = base64url.replace(/-/g, '+').replace(/_/g, '/');
  var binaryString = window.atob(base64);
  var len = binaryString.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}
function encode(input, encoding) {
  if (encoding === void 0) {
    encoding = 'utf8';
  }
  if (isBuffer(input)) {
    return fromBase64(stringFromBuffer(input, 'base64'));
  }
  return fromBase64(stringFromBuffer(bufferFromString(input, encoding), 'base64'));
}
function decode(base64url, encoding) {
  if (encoding === void 0) {
    encoding = 'utf8';
  }
  return stringFromBuffer(toBuffer(base64url), encoding);
}
function toBase64(base64url) {
  if (base64url instanceof ArrayBuffer) {
    base64url = stringFromBuffer(base64url, 'utf8');
  } else if (isBuffer(base64url)) {
    base64url = base64url.toString();
  }
  return pad_string_1.default(base64url).replace(/\-/g, '+').replace(/_/g, '/');
}
function fromBase64(base64) {
  return base64.replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
}
var base64url = encode;
base64url.encode = encode;
base64url.decode = decode;
base64url.toBase64 = toBase64;
base64url.fromBase64 = fromBase64;
base64url.toBuffer = toBuffer;
exports.default = base64url;