"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sdJwtVcFromCompact = void 0;
const utils_1 = require("@sd-jwt/utils");
const fromCompact_1 = require("../sdJwt/fromCompact");
const sdJwtVcFromCompact = compact => {
  const sdJwt = (0, fromCompact_1.sdJwtFromCompact)(compact);
  try {
    (0, utils_1.assertClaimInObject)(sdJwt.header, 'typ', 'vc+sd-jwt');
    (0, utils_1.assertClaimInObject)(sdJwt.header, 'alg');
    (0, utils_1.assertClaimInObject)(sdJwt.payload, 'iss');
    (0, utils_1.assertClaimInObject)(sdJwt.payload, 'vct');
    (0, utils_1.assertClaimInObject)(sdJwt.payload, 'iat');
  } catch (e) {
    if (e instanceof Error) {
      e.message = `jwt is not valid for usage with sd-jwt-vc. Error: ${e.message}`;
    }
    throw e;
  }
  return sdJwt;
};
exports.sdJwtVcFromCompact = sdJwtVcFromCompact;
