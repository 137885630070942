"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sdJwtFromCompact = void 0;
const fromString_1 = require("../disclosures/fromString");
const jwt_1 = require("../jwt");
const keyBinding_1 = require("../keyBinding");
const sdJwtFromCompact = compact => {
  if (!compact.includes('~')) {
    throw new Error('compact is not a valid sd-jwt. It must contain at least one ~');
  }
  const [jwtWithoutDisclosures, ...encodedDisclosures] = compact.split('~');
  const {
    header,
    payload,
    signature
  } = (0, jwt_1.jwtFromCompact)(jwtWithoutDisclosures);
  // No KB-JWT or disclosures
  if (encodedDisclosures.length === 1 && encodedDisclosures[0] === '') {
    return {
      header,
      payload,
      signature
    };
  }
  // If the disclosure array ends with an `~` we do not have
  // a key binding and `String.split` takes it as an empty string
  // as element which we would not like to include in the disclosures.
  const compactKeyBinding = encodedDisclosures.pop();
  const hasKeyBinding = compactKeyBinding && compactKeyBinding !== '';
  const keyBinding = hasKeyBinding ? (0, keyBinding_1.keyBindingFromCompact)(compactKeyBinding) : undefined;
  const disclosures = encodedDisclosures.map(fromString_1.disclosureFromString);
  return {
    header,
    payload,
    signature,
    keyBinding,
    disclosures
  };
};
exports.sdJwtFromCompact = sdJwtFromCompact;
