"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.calculateSdHash = void 0;
const utils_1 = require("@sd-jwt/utils");
function calculateSdHash(compactSdJwt, hasherAndAlgorithm) {
  // If we don't end with a '~' we probably need to remove the
  // kb-jwt first
  const sdJwtWithoutKbJwt = compactSdJwt.endsWith('~') ? compactSdJwt : compactSdJwt.split('~').slice(0, -1).join('~') + '~';
  const hashResult = hasherAndAlgorithm.hasher(sdJwtWithoutKbJwt, hasherAndAlgorithm.algorithm);
  return (0, utils_1.isPromise)(hashResult) ? hashResult.then(hash => utils_1.Base64url.encode(hash)) : utils_1.Base64url.encode(hashResult);
}
exports.calculateSdHash = calculateSdHash;
