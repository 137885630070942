"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.agentContext = void 0;
function agentContext(agent) {
  return {
    agent
  };
}
exports.agentContext = agentContext;
