"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.errorResolutionResult = exports.DefaultConfig = exports.DefaultURLs = exports.Constants = void 0;
exports.Constants = {
  INVALID_DID: 'invalidDid',
  URL_PATHNAME_REGEX: /.+?:\/\/.+?(\/.+?)(?:#|\?|$)/
};
exports.DefaultURLs = {
  /** Registrar URL's*/
  CREATE: 'https://uniregistrar.io/1.0/create',
  UPDATE: 'https://uniregistrar.io/1.0/update',
  DEACTIVATE: 'https://uniregistrar.io/1.0/deactivate',
  /** Resolver URL's*/
  RESOLVE: 'https://dev.uniresolver.io/1.0/identifiers'
};
exports.DefaultConfig = {
  createURL: exports.DefaultURLs.CREATE,
  updateURL: exports.DefaultURLs.UPDATE,
  deactivateURL: exports.DefaultURLs.DEACTIVATE,
  resolveURL: exports.DefaultURLs.RESOLVE
};
const EMPTY_RESULT = {
  didResolutionMetadata: {},
  didDocument: null,
  didDocumentMetadata: {}
};
const errorResolutionResult = error => {
  return new Promise(resolve => {
    resolve(Object.assign(Object.assign({}, EMPTY_RESULT), {
      didResolutionMetadata: {
        error
      }
    }));
  });
};
exports.errorResolutionResult = errorResolutionResult;
