"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.assertClaimInObject = void 0;
const getValueByKeyAnyLevel_1 = require("./getValueByKeyAnyLevel");
const simpleDeepEqual_1 = require("./simpleDeepEqual");
const assertClaimInObject = (object, claimKey, claimValue) => {
  const value = (0, getValueByKeyAnyLevel_1.getValueByKeyAnyLevel)(object, claimKey);
  if (!value) {
    throw new Error(`Claim key '${claimKey}' not found in any level`);
  }
  if (claimValue && !(0, simpleDeepEqual_1.simpleDeepEqual)(value, claimValue)) {
    throw new Error(`Claim key '${claimKey}' was found, but values did not match`);
  }
};
exports.assertClaimInObject = assertClaimInObject;
