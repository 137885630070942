"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.keyBindingFromCompact = void 0;
const utils_1 = require("@sd-jwt/utils");
const jwt_1 = require("../jwt");
const keyBindingFromCompact = compact => {
  const jwt = (0, jwt_1.jwtFromCompact)(compact);
  try {
    (0, utils_1.assertClaimInObject)(jwt.header, 'typ', 'kb+jwt');
    (0, utils_1.assertClaimInObject)(jwt.header, 'alg');
    (0, utils_1.assertClaimInObject)(jwt.payload, 'iat');
    (0, utils_1.assertClaimInObject)(jwt.payload, 'nonce');
    (0, utils_1.assertClaimInObject)(jwt.payload, 'aud');
  } catch (e) {
    if (e instanceof Error) {
      e.message = `jwt is not valid for usage with key binding. Error: ${e.message}`;
    }
    throw e;
  }
  return jwt;
};
exports.keyBindingFromCompact = keyBindingFromCompact;
