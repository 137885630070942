{
  "IOID4VCIRestClient": {
    "components": {
      "schemas": {
        "IOID4VCIClientCreateOfferUriRequestArgs": {
          "type": "object",
          "properties": {
            "credentials": {
              "type": "array",
              "items": {
                "anyOf": [
                  {
                    "$ref": "#/components/schemas/CredentialOfferFormat"
                  },
                  {
                    "type": "string"
                  }
                ]
              }
            },
            "grants": {
              "$ref": "#/components/schemas/Grant"
            },
            "credentialDataSupplierInput": {
              "$ref": "#/components/schemas/CredentialDataSupplierInput"
            },
            "agentBaseUrl": {
              "type": "string"
            }
          },
          "required": [
            "credentials",
            "grants"
          ]
        },
        "CredentialOfferFormat": {
          "anyOf": [
            {
              "type": "object",
              "properties": {
                "format": {
                  "anyOf": [
                    {
                      "$ref": "#/components/schemas/OID4VCICredentialFormat"
                    },
                    {
                      "type": "string"
                    }
                  ]
                },
                "credential_definition": {
                  "$ref": "#/components/schemas/JsonLdIssuerCredentialDefinition"
                }
              },
              "required": [
                "credential_definition",
                "format"
              ]
            },
            {
              "type": "object",
              "properties": {
                "format": {
                  "anyOf": [
                    {
                      "$ref": "#/components/schemas/OID4VCICredentialFormat"
                    },
                    {
                      "type": "string"
                    }
                  ]
                },
                "types": {
                  "type": "array",
                  "items": {
                    "type": "string"
                  }
                }
              },
              "required": [
                "format",
                "types"
              ]
            },
            {
              "type": "object",
              "properties": {
                "format": {
                  "anyOf": [
                    {
                      "$ref": "#/components/schemas/OID4VCICredentialFormat"
                    },
                    {
                      "type": "string"
                    }
                  ]
                },
                "vct": {
                  "type": "string"
                },
                "claims": {
                  "$ref": "#/components/schemas/IssuerCredentialSubject"
                }
              },
              "required": [
                "format",
                "vct"
              ]
            }
          ]
        },
        "OID4VCICredentialFormat": {
          "type": "string",
          "enum": [
            "jwt_vc_json",
            "jwt_vc_json-ld",
            "ldp_vc",
            "vc+sd-jwt",
            "jwt_vc"
          ]
        },
        "JsonLdIssuerCredentialDefinition": {
          "type": "object",
          "properties": {
            "@context": {
              "type": "array",
              "items": {
                "$ref": "#/components/schemas/ICredentialContextType"
              }
            },
            "types": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "credentialSubject": {
              "$ref": "#/components/schemas/IssuerCredentialSubject"
            }
          },
          "required": [
            "@context",
            "types"
          ]
        },
        "ICredentialContextType": {
          "anyOf": [
            {
              "type": "object",
              "properties": {
                "name": {
                  "type": "string"
                },
                "did": {
                  "type": "string"
                }
              }
            },
            {
              "type": "string"
            }
          ]
        },
        "IssuerCredentialSubject": {
          "type": "object",
          "additionalProperties": {
            "$ref": "#/components/schemas/IssuerCredentialSubjectDisplay"
          }
        },
        "IssuerCredentialSubjectDisplay": {
          "type": "object",
          "properties": {
            "mandatory": {
              "type": "boolean"
            },
            "value_type": {
              "type": "string"
            },
            "display": {
              "type": "array",
              "items": {
                "$ref": "#/components/schemas/NameAndLocale"
              }
            }
          }
        },
        "NameAndLocale": {
          "type": "object",
          "properties": {
            "name": {
              "type": "string"
            },
            "locale": {
              "type": "string"
            }
          },
          "additionalProperties": {}
        },
        "Grant": {
          "type": "object",
          "properties": {
            "authorization_code": {
              "$ref": "#/components/schemas/GrantAuthorizationCode"
            },
            "urn:ietf:params:oauth:grant-type:pre-authorized_code": {
              "$ref": "#/components/schemas/GrantUrnIetf"
            }
          }
        },
        "GrantAuthorizationCode": {
          "type": "object",
          "properties": {
            "issuer_state": {
              "type": "string",
              "description": "OPTIONAL. String value created by the Credential Issuer and opaque to the Wallet that is used to bind the subsequent Authorization Request with the Credential Issuer to a context set up during previous steps."
            }
          }
        },
        "GrantUrnIetf": {
          "type": "object",
          "properties": {
            "pre-authorized_code": {
              "type": "string",
              "description": "REQUIRED. The code representing the Credential Issuer's authorization for the Wallet to obtain Credentials of a certain type."
            },
            "user_pin_required": {
              "type": "boolean",
              "description": "OPTIONAL. Boolean value specifying whether the Credential Issuer expects presentation of a user PIN along with the Token Request in a Pre-Authorized Code Flow. Default is false."
            }
          },
          "required": [
            "pre-authorized_code",
            "user_pin_required"
          ]
        },
        "CredentialDataSupplierInput": {
          "description": "Optional storage that can help the credential Data Supplier. For instance to store credential input data during offer creation, if no additional data can be supplied later on"
        },
        "IOID4VCIClientCreateOfferUriResponse": {
          "type": "object",
          "properties": {
            "uri": {
              "type": "string"
            },
            "qrCodeDataUri": {
              "type": "string"
            },
            "userPin": {
              "type": "string"
            },
            "userPinLength": {
              "type": "number"
            },
            "userPinRequired": {
              "type": "boolean"
            }
          },
          "required": [
            "uri",
            "userPinRequired"
          ]
        },
        "IOID4VCIClientGetIssueStatusArgs": {
          "type": "object",
          "properties": {
            "id": {
              "type": "string"
            },
            "baseUrl": {
              "type": "string"
            }
          },
          "required": [
            "id"
          ]
        },
        "IssueStatusResponse": {
          "type": "object",
          "properties": {
            "createdAt": {
              "type": "number"
            },
            "lastUpdatedAt": {
              "type": "number"
            },
            "status": {
              "$ref": "#/components/schemas/IssueStatus"
            },
            "error": {
              "type": "string"
            },
            "clientId": {
              "type": "string"
            }
          },
          "required": [
            "createdAt",
            "lastUpdatedAt",
            "status"
          ]
        },
        "IssueStatus": {
          "type": "string",
          "enum": [
            "OFFER_CREATED",
            "OFFER_URI_RETRIEVED",
            "ACCESS_TOKEN_CREATED",
            "CREDENTIAL_REQUEST_RECEIVED",
            "CREDENTIAL_ISSUED",
            "ERROR"
          ]
        }
      },
      "methods": {
        "oid4vciClientCreateOfferUri": {
          "description": "",
          "arguments": {
            "$ref": "#/components/schemas/IOID4VCIClientCreateOfferUriRequestArgs"
          },
          "returnType": {
            "$ref": "#/components/schemas/IOID4VCIClientCreateOfferUriResponse"
          }
        },
        "oid4vciClientGetIssueStatus": {
          "description": "",
          "arguments": {
            "$ref": "#/components/schemas/IOID4VCIClientGetIssueStatusArgs"
          },
          "returnType": {
            "$ref": "#/components/schemas/IssueStatusResponse"
          }
        }
      }
    }
  }
}