function unwrapExports(x) {
  return x && x.__esModule && Object.prototype.hasOwnProperty.call(x, 'default') ? x['default'] : x;
}
function createCommonjsModule(fn, module) {
  return module = {
    exports: {}
  }, fn(module, module.exports), module.exports;
}
var context = createCommonjsModule(function (module, exports) {
  /*! For license information please see context.js.LICENSE.txt */
  (() => {
    var t = {
        380: t => {
          t.exports = {
            CONTEXT_FILENAME: "vc-status-list-v1.jsonld",
            CONTEXT_URL_V1: "https://w3id.org/vc/status-list/2021/v1",
            CBORLD_VALUE: 32
          };
        },
        592: t => {
          t.exports = {
            "@context": {
              "@protected": !0,
              StatusList2021Credential: {
                "@id": "https://w3id.org/vc/status-list#StatusList2021Credential",
                "@context": {
                  "@protected": !0,
                  id: "@id",
                  type: "@type",
                  description: "http://schema.org/description",
                  name: "http://schema.org/name"
                }
              },
              StatusList2021: {
                "@id": "https://w3id.org/vc/status-list#StatusList2021",
                "@context": {
                  "@protected": !0,
                  id: "@id",
                  type: "@type",
                  statusPurpose: "https://w3id.org/vc/status-list#statusPurpose",
                  encodedList: "https://w3id.org/vc/status-list#encodedList"
                }
              },
              StatusList2021Entry: {
                "@id": "https://w3id.org/vc/status-list#StatusList2021Entry",
                "@context": {
                  "@protected": !0,
                  id: "@id",
                  type: "@type",
                  statusPurpose: "https://w3id.org/vc/status-list#statusPurpose",
                  statusListIndex: "https://w3id.org/vc/status-list#statusListIndex",
                  statusListCredential: {
                    "@id": "https://w3id.org/vc/status-list#statusListCredential",
                    "@type": "@id"
                  }
                }
              }
            }
          };
        },
        588: (t, s, e) => {
          const i = e(592),
            o = e(380),
            {
              CONTEXT_URL_V1: r,
              CBORLD_VALUE: a
            } = o,
            p = new Map();
          p.set(r, i);
          const d = new Map();
          d.set(r, a), t.exports = {
            constants: o,
            contexts: p,
            appContextMap: d,
            CONTEXT_URL_V1: r,
            CONTEXT_V1: i
          };
        }
      },
      s = {},
      e = function e(i) {
        var o = s[i];
        if (void 0 !== o) return o.exports;
        var r = s[i] = {
          exports: {}
        };
        return t[i](r, r.exports, e), r.exports;
      }(588),
      i = exports;
    for (var o in e) i[o] = e[o];
    e.__esModule && Object.defineProperty(i, "__esModule", {
      value: !0
    });
  })();
});
var context$1 = unwrapExports(context);
var context_1 = context.contexts;
var context_2 = context.constants;
var context_3 = context.CONTEXT_V1;
var context_4 = context.CONTEXT_URL_V1;
var context_5 = context.appContextMap;
export { context_4 as CONTEXT_URL_V1, context_3 as CONTEXT_V1, context_5 as appContextMap, context_2 as constants, context_1 as contexts, context$1 as default };