"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.simpleDeepEqual = void 0;
function simpleDeepEqual(lhs, rhs) {
  if (lhs === rhs) return true;
  if (typeof lhs !== 'object' || typeof rhs !== 'object') return false;
  const l = Object.assign({}, lhs);
  const r = Object.assign({}, rhs);
  Object.keys(l).forEach(key => l[key] === undefined && delete l[key]);
  Object.keys(r).forEach(key => r[key] === undefined && delete r[key]);
  const keys1 = Object.keys(l);
  const keys2 = Object.keys(r);
  if (keys1.length !== keys2.length) return false;
  return keys1.every(key => simpleDeepEqual(l[key], r[key]));
}
exports.simpleDeepEqual = simpleDeepEqual;
