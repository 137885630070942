var e,
  t,
  n = "object" == typeof Reflect ? Reflect : null,
  r = n && "function" == typeof n.apply ? n.apply : function (e, t, n) {
    return Function.prototype.apply.call(e, t, n);
  };
t = n && "function" == typeof n.ownKeys ? n.ownKeys : Object.getOwnPropertySymbols ? function (e) {
  return Object.getOwnPropertyNames(e).concat(Object.getOwnPropertySymbols(e));
} : function (e) {
  return Object.getOwnPropertyNames(e);
};
var i = Number.isNaN || function (e) {
  return e != e;
};
function o() {
  o.init.call(this);
}
e = o, o.EventEmitter = o, o.prototype._events = void 0, o.prototype._eventsCount = 0, o.prototype._maxListeners = void 0;
var s = 10;
function u(e) {
  if ("function" != typeof e) throw new TypeError('The "listener" argument must be of type Function. Received type ' + typeof e);
}
function f(e) {
  return void 0 === e._maxListeners ? o.defaultMaxListeners : e._maxListeners;
}
function v(e, t, n, r) {
  var i, o, s, v;
  if (u(n), void 0 === (o = e._events) ? (o = e._events = Object.create(null), e._eventsCount = 0) : (void 0 !== o.newListener && (e.emit("newListener", t, n.listener ? n.listener : n), o = e._events), s = o[t]), void 0 === s) s = o[t] = n, ++e._eventsCount;else if ("function" == typeof s ? s = o[t] = r ? [n, s] : [s, n] : r ? s.unshift(n) : s.push(n), (i = f(e)) > 0 && s.length > i && !s.warned) {
    s.warned = !0;
    var a = new Error("Possible EventEmitter memory leak detected. " + s.length + " " + String(t) + " listeners added. Use emitter.setMaxListeners() to increase limit");
    a.name = "MaxListenersExceededWarning", a.emitter = e, a.type = t, a.count = s.length, v = a, console && console.warn && console.warn(v);
  }
  return e;
}
function a() {
  if (!this.fired) return this.target.removeListener(this.type, this.wrapFn), this.fired = !0, 0 === arguments.length ? this.listener.call(this.target) : this.listener.apply(this.target, arguments);
}
function l(e, t, n) {
  var r = {
      fired: !1,
      wrapFn: void 0,
      target: e,
      type: t,
      listener: n
    },
    i = a.bind(r);
  return i.listener = n, r.wrapFn = i, i;
}
function h(e, t, n) {
  var r = e._events;
  if (void 0 === r) return [];
  var i = r[t];
  return void 0 === i ? [] : "function" == typeof i ? n ? [i.listener || i] : [i] : n ? function (e) {
    for (var t = new Array(e.length), n = 0; n < t.length; ++n) t[n] = e[n].listener || e[n];
    return t;
  }(i) : c(i, i.length);
}
function p(e) {
  var t = this._events;
  if (void 0 !== t) {
    var n = t[e];
    if ("function" == typeof n) return 1;
    if (void 0 !== n) return n.length;
  }
  return 0;
}
function c(e, t) {
  for (var n = new Array(t), r = 0; r < t; ++r) n[r] = e[r];
  return n;
}
Object.defineProperty(o, "defaultMaxListeners", {
  enumerable: !0,
  get: function () {
    return s;
  },
  set: function (e) {
    if ("number" != typeof e || e < 0 || i(e)) throw new RangeError('The value of "defaultMaxListeners" is out of range. It must be a non-negative number. Received ' + e + ".");
    s = e;
  }
}), o.init = function () {
  void 0 !== this._events && this._events !== Object.getPrototypeOf(this)._events || (this._events = Object.create(null), this._eventsCount = 0), this._maxListeners = this._maxListeners || void 0;
}, o.prototype.setMaxListeners = function (e) {
  if ("number" != typeof e || e < 0 || i(e)) throw new RangeError('The value of "n" is out of range. It must be a non-negative number. Received ' + e + ".");
  return this._maxListeners = e, this;
}, o.prototype.getMaxListeners = function () {
  return f(this);
}, o.prototype.emit = function (e) {
  for (var t = [], n = 1; n < arguments.length; n++) t.push(arguments[n]);
  var i = "error" === e,
    o = this._events;
  if (void 0 !== o) i = i && void 0 === o.error;else if (!i) return !1;
  if (i) {
    var s;
    if (t.length > 0 && (s = t[0]), s instanceof Error) throw s;
    var u = new Error("Unhandled error." + (s ? " (" + s.message + ")" : ""));
    throw u.context = s, u;
  }
  var f = o[e];
  if (void 0 === f) return !1;
  if ("function" == typeof f) r(f, this, t);else {
    var v = f.length,
      a = c(f, v);
    for (n = 0; n < v; ++n) r(a[n], this, t);
  }
  return !0;
}, o.prototype.addListener = function (e, t) {
  return v(this, e, t, !1);
}, o.prototype.on = o.prototype.addListener, o.prototype.prependListener = function (e, t) {
  return v(this, e, t, !0);
}, o.prototype.once = function (e, t) {
  return u(t), this.on(e, l(this, e, t)), this;
}, o.prototype.prependOnceListener = function (e, t) {
  return u(t), this.prependListener(e, l(this, e, t)), this;
}, o.prototype.removeListener = function (e, t) {
  var n, r, i, o, s;
  if (u(t), void 0 === (r = this._events)) return this;
  if (void 0 === (n = r[e])) return this;
  if (n === t || n.listener === t) 0 == --this._eventsCount ? this._events = Object.create(null) : (delete r[e], r.removeListener && this.emit("removeListener", e, n.listener || t));else if ("function" != typeof n) {
    for (i = -1, o = n.length - 1; o >= 0; o--) if (n[o] === t || n[o].listener === t) {
      s = n[o].listener, i = o;
      break;
    }
    if (i < 0) return this;
    0 === i ? n.shift() : !function (e, t) {
      for (; t + 1 < e.length; t++) e[t] = e[t + 1];
      e.pop();
    }(n, i), 1 === n.length && (r[e] = n[0]), void 0 !== r.removeListener && this.emit("removeListener", e, s || t);
  }
  return this;
}, o.prototype.off = o.prototype.removeListener, o.prototype.removeAllListeners = function (e) {
  var t, n, r;
  if (void 0 === (n = this._events)) return this;
  if (void 0 === n.removeListener) return 0 === arguments.length ? (this._events = Object.create(null), this._eventsCount = 0) : void 0 !== n[e] && (0 == --this._eventsCount ? this._events = Object.create(null) : delete n[e]), this;
  if (0 === arguments.length) {
    var i,
      o = Object.keys(n);
    for (r = 0; r < o.length; ++r) "removeListener" !== (i = o[r]) && this.removeAllListeners(i);
    return this.removeAllListeners("removeListener"), this._events = Object.create(null), this._eventsCount = 0, this;
  }
  if ("function" == typeof (t = n[e])) this.removeListener(e, t);else if (void 0 !== t) for (r = t.length - 1; r >= 0; r--) this.removeListener(e, t[r]);
  return this;
}, o.prototype.listeners = function (e) {
  return h(this, e, !0);
}, o.prototype.rawListeners = function (e) {
  return h(this, e, !1);
}, o.listenerCount = function (e, t) {
  return "function" == typeof e.listenerCount ? e.listenerCount(t) : p.call(e, t);
}, o.prototype.listenerCount = p, o.prototype.eventNames = function () {
  return this._eventsCount > 0 ? t(this._events) : [];
};
var y = e;
y.EventEmitter;
y.defaultMaxListeners;
y.init;
y.listenerCount;
y.EventEmitter;
y.defaultMaxListeners;
y.init;
y.listenerCount;
export { y };