"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getValueByKeyAnyLevel = void 0;
const getValueByKeyAnyLevel = (obj, key) => {
  // Check if the current object has the key
  if (obj && obj.hasOwnProperty(key)) {
    return obj[key];
  }
  // If not found in the current object, iterate over its properties
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop) && typeof obj[prop] === 'object') {
      const result = (0, exports.getValueByKeyAnyLevel)(obj[prop], key);
      if (result !== undefined) {
        return result;
      }
    }
  }
};
exports.getValueByKeyAnyLevel = getValueByKeyAnyLevel;
