/**
 * Mapping of known key types({@link TKeyType}) to the known algorithms({@link TAlg}) they should support.
 *
 * @public
 */
export const KEY_ALG_MAPPING = {
  Secp256k1: ['ES256K', 'ES256K-R'],
  Secp256r1: ['ES256', 'ECDH', 'ECDH-ES', 'ECDH-1PU'],
  Ed25519: ['EdDSA'],
  X25519: ['ECDH', 'ECDH-ES', 'ECDH-1PU'],
  Bls12381G1: [],
  Bls12381G2: []
};
