"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.jwtFromCompact = void 0;
const utils_1 = require("@sd-jwt/utils");
const jwtFromCompact = compact => {
  if (compact.includes('~')) {
    throw new Error('compact JWT includes `~` which is only allowed in an sd-jwt. Please use sdJwtFromCompact() instead.');
  }
  if ((compact.match(/\./g) || []).length !== 2) {
    throw new Error('compact JWT must include two periods (.)');
  }
  const [compactHeader, compactPayload, encodedSignature] = compact.split('.');
  if (!encodedSignature || encodedSignature.length === 0) {
    throw new Error('A signature must be provided within the context of sd-jwt');
  }
  const header = utils_1.Base64url.decodeToJson(compactHeader);
  const payload = utils_1.Base64url.decodeToJson(compactPayload);
  const signature = utils_1.Base64url.decode(encodedSignature);
  return {
    header,
    payload,
    signature
  };
};
exports.jwtFromCompact = jwtFromCompact;
