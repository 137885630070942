"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.decodeDisclosuresInPayload = void 0;
function decodeDisclosuresInPayload(payload, disclosures, newPayload = {}) {
  const entries = Object.entries(payload);
  // Loop over de payload
  for (let i = 0; i < entries.length; i++) {
    const [key, value] = entries[i];
    // See whether we have an `_sd` key with an array of disclosures.
    const foundDisclosures = shouldInsertDisclosure(key, value, disclosures);
    // Add the disclosed items to the pretty payload
    foundDisclosures.forEach(d => {
      newPayload[d.key] = d.value;
    });
    // Skip the rest as  `_sd` is a special case
    if (key === '_sd') {
      continue;
    }
    // Include all the primitive claims into the new payload
    if (shouldIncludeCleartextClaim(key, value)) {
      newPayload[key] = value;
      continue;
    }
    if (typeof value === 'object' && Array.isArray(value)) {
      newPayload[key] = swapClaimsInsideArray(value, disclosures);
      continue;
    }
    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      newPayload[key] = decodeDisclosuresInPayload(value, disclosures);
    }
  }
  return newPayload;
}
exports.decodeDisclosuresInPayload = decodeDisclosuresInPayload;
function swapClaimsInsideArray(array, disclosures) {
  const processedArray = [];
  for (const el of array) {
    if (typeof el === 'object' && el !== null && '...' in el) {
      const hash = el['...'];
      let disclosureFound = false;
      let disclosureValue;
      disclosureLoop: for (const d of disclosures) {
        if (d.digest === hash && d.key === undefined) {
          disclosureValue = d.value;
          disclosureFound = true;
          break disclosureLoop;
        }
      }
      processedArray.push(disclosureFound ? disclosureValue : el);
    } else {
      processedArray.push(el);
    }
  }
  return processedArray;
}
function shouldInsertDisclosure(key, value, disclosures) {
  if (key !== '_sd') return [];
  if (!Array.isArray(value)) return [];
  const filteredDisclosures = [];
  for (const d of disclosures) {
    if (value.includes(d.digest)) {
      filteredDisclosures.push(d);
    }
  }
  return filteredDisclosures;
}
function shouldIncludeCleartextClaim(key, value) {
  return key !== '_sd' && key !== '_sd_alg' && typeof value !== 'object';
}
