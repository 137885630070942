"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hasByPath = exports.getByPath = exports.deleteByPath = exports.getAllKeys = exports.isObject = void 0;
function isObject(input) {
  return typeof input === 'object' && input !== null && !Array.isArray(input);
}
exports.isObject = isObject;
function getAllKeys(object, keys = []) {
  if (typeof object !== 'object' || typeof object === null) return keys;
  const record = object;
  const objectKeys = Object.keys(record);
  const objectValues = Object.values(record);
  keys.push(...objectKeys);
  for (const objectValue of objectValues) {
    if (typeof objectValue === 'object' && objectValue !== null && !Array.isArray(objectValue)) {
      getAllKeys(objectValue, keys);
    }
  }
  return keys;
}
exports.getAllKeys = getAllKeys;
function deleteByPath(object, path) {
  let currentObject = object;
  const parts = [...path];
  const last = parts.pop();
  for (const part of parts) {
    currentObject = currentObject[part];
    if (!currentObject) {
      return;
    }
  }
  if (last) {
    delete currentObject[last];
  }
}
exports.deleteByPath = deleteByPath;
function getByPath(item, path) {
  let current = item;
  for (const key of path) {
    if (Array.isArray(current)) {
      const keyAsNumber = Number(key);
      if (isNaN(keyAsNumber)) {
        throw new Error(`Unable to get ${path.join('.')} from array ${item}. ${key} is not a number.`);
      }
      if (keyAsNumber >= current.length) {
        throw new Error(`Unable to get ${path.join('.')} from array ${item}. ${key} is out of bounds.`);
      }
      current = current[keyAsNumber];
    } else if (typeof current === 'object' && current !== null) {
      if (!(key in current)) {
        throw new Error(`Unable to get ${path.join('.')} from ${item}. ${key} does not exists in ${current}.`);
      }
      current = current[key];
    } else {
      throw new Error(`Unable to get ${path.join('.')} from ${item}. ${key} is not an object or array.`);
    }
  }
  return current;
}
exports.getByPath = getByPath;
function hasByPath(item, path) {
  try {
    getByPath(item, path);
    return true;
  } catch (_a) {
    return false;
  }
}
exports.hasByPath = hasByPath;
