"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HEIF = void 0;
const utils_1 = require("./utils");
const brandMap = {
  avif: 'avif',
  mif1: 'heif',
  msf1: 'heif',
  // hief-sequence
  heic: 'heic',
  heix: 'heic',
  hevc: 'heic',
  // heic-sequence
  hevx: 'heic' // heic-sequence
};
exports.HEIF = {
  validate(buffer) {
    const ftype = (0, utils_1.toUTF8String)(buffer, 4, 8);
    const brand = (0, utils_1.toUTF8String)(buffer, 8, 12);
    return 'ftyp' === ftype && brand in brandMap;
  },
  calculate(buffer) {
    // Based on https://nokiatech.github.io/heif/technical.html
    const metaBox = (0, utils_1.findBox)(buffer, 'meta', 0);
    const iprpBox = metaBox && (0, utils_1.findBox)(buffer, 'iprp', metaBox.offset + 12);
    const ipcoBox = iprpBox && (0, utils_1.findBox)(buffer, 'ipco', iprpBox.offset + 8);
    const ispeBox = ipcoBox && (0, utils_1.findBox)(buffer, 'ispe', ipcoBox.offset + 8);
    if (ispeBox) {
      return {
        height: (0, utils_1.readUInt32BE)(buffer, ispeBox.offset + 16),
        width: (0, utils_1.readUInt32BE)(buffer, ispeBox.offset + 12),
        type: (0, utils_1.toUTF8String)(buffer, 8, 12)
      };
    }
    throw new TypeError('Invalid HEIF, no size found');
  }
};