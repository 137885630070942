import { Injectable } from '@angular/core';
import {
  IDIDManager,
  IDataStore,
  IDataStoreORM,
  IKeyManager,
  IResolver,
  TAgent,
  createAgent,
} from '@veramo/core';
import {
  IOID4VCIRestClient,
  OID4VCIRestClient,
} from '@sphereon/ssi-sdk.oid4vci-issuer-rest-client';
import { environment } from '../environments/environment';
import {
  ISIOPv2OID4VPRPRestClient,
  SIOPv2OID4VPRPRestClient,
} from '@sphereon/ssi-sdk.siopv2-oid4vp-rp-rest-client';
import { AgentRestClient } from '@veramo/remote-client';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { IOID4VCIStore } from '@sphereon/ssi-sdk.oid4vci-issuer-store';
import { IPresentationExchange } from '@sphereon/ssi-sdk.presentation-exchange';
import { JWTPayload } from '@sphereon/oid4vci-common';
import { KeycloakService } from 'keycloak-angular';
import { AgentCore, AgentCoreType } from '@bcrl/agent-core';
import { IVCStore } from '@bcrl/vc-store';

export interface PermissionJWT extends JWTPayload {
  permissions: string[];
}

type AgentType = AgentCoreType &
  IOID4VCIRestClient &
  IDataStoreORM &
  IVCStore &
  IResolver &
  IDIDManager &
  IKeyManager &
  IOID4VCIStore &
  IDataStore &
  ISIOPv2OID4VPRPRestClient &
  IPresentationExchange;

@Injectable({
  providedIn: 'root',
})
export class AgentService extends AgentCore {
  public override agent!: TAgent<AgentType>;

  constructor(
    private httpClient: HttpClient,
    protected readonly keycloak: KeycloakService
  ) {
    super();
  }

  async init(namespace: string) {
    console.log('init agent for namespace: ', namespace);
    window['env']['namespace'] = namespace;
    //fetch the methods from the server so we do not need to hardcode them.
    const enabledIssuerMethods = await firstValueFrom(
      this.httpClient.get<string[]>(
        `${environment.agent}/agent/exposed-methods`
      )
    ).catch((error: HttpErrorResponse) => {
      if (error.status === 404) {
        throw new Error(`namespace ${namespace} does not exist.`);
      }
      throw new Error('failed to query server');
    });
    this.agent = createAgent<AgentType>({
      plugins: [
        new AgentRestClient({
          url: `${environment.agent}/agent`,
          enabledMethods: enabledIssuerMethods,
          headers: this.getHeaders.bind(this),
        }),
        new OID4VCIRestClient({
          baseUrl: environment.agent,
          authentication: {
            enabled: true,
            bearerToken: this.getToken.bind(this),
          },
        }),
        new SIOPv2OID4VPRPRestClient({
          baseUrl: environment.agent,
          authentication: {
            enabled: true,
            bearerToken: this.getToken.bind(this),
          },
        }),
        ...this.plugins,
      ],
    });
  }

  getToken() {
    return this.keycloak.getToken();
  }

  getHeaders() {
    return this.keycloak.getToken().then((token) => ({
      Authorization: `Bearer ${token}`,
    }));
  }
}
