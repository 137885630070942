'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DIDRegistrationRequest = void 0;
class DIDRegistrationRequest {
  constructor(request) {
    if (request) {
      this.jobId = request.jobId;
      this.didDocument = request.didDocument;
      this.options = request.options;
      this.secret = request.secret;
    }
  }
}
exports.DIDRegistrationRequest = DIDRegistrationRequest;
