"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __exportStar = this && this.__exportStar || function (m, exports) {
  for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.downloadImage = exports.getImageDimensions = exports.getImageMediaType = exports.flattenMigrations = exports.flattenArray = void 0;
__exportStar(require("./encoding"), exports);
var database_1 = require("./database");
Object.defineProperty(exports, "flattenArray", {
  enumerable: true,
  get: function () {
    return database_1.flattenArray;
  }
});
Object.defineProperty(exports, "flattenMigrations", {
  enumerable: true,
  get: function () {
    return database_1.flattenMigrations;
  }
});
var image_1 = require("./image");
Object.defineProperty(exports, "getImageMediaType", {
  enumerable: true,
  get: function () {
    return image_1.getImageMediaType;
  }
});
Object.defineProperty(exports, "getImageDimensions", {
  enumerable: true,
  get: function () {
    return image_1.getImageDimensions;
  }
});
Object.defineProperty(exports, "downloadImage", {
  enumerable: true,
  get: function () {
    return image_1.downloadImage;
  }
});
__exportStar(require("./vc"), exports);
