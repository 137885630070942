"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CredentialStatusPlugin = void 0;
const utils_1 = require("@veramo/utils");
const credential_status_1 = require("credential-status");
/**
 * This plugin implements the {@link @veramo/core#ICredentialStatusVerifier | ICredentialStatusVerifier}
 * interface.
 *
 * This aggregates some {@link credential-status#StatusMethod | credential status implementations} to provide a second
 * layer of validation when verifying Verifiable Credentials.
 *
 * This is used for the discovery of information about the current status of a verifiable credential, such as whether
 * it is suspended or revoked. The precise contents of the credential status information is determined by the specific
 * `credentialStatus` type definition.
 *
 * The results provided by this plugin depend on whether the {@link credential-status#StatusMethod | StatusMethod}
 * required by the credential is installed.
 *
 * @see {@link credential-status#Status}
 *
 * @beta This API may change without a BREAKING CHANGE notice.
 */
class CredentialStatusPlugin {
  constructor(registry = {}) {
    this.status = new credential_status_1.Status(registry);
    this.methods = {
      checkCredentialStatus: this.checkCredentialStatus.bind(this)
    };
  }
  checkCredentialStatus(args, context) {
    return __awaiter(this, void 0, void 0, function* () {
      let didDoc = args.didDocumentOverride;
      if (!didDoc) {
        const issuerDid = (0, utils_1.extractIssuer)(args.credential);
        didDoc = yield (0, utils_1.resolveDidOrThrow)(issuerDid, context);
      }
      const statusCheck = yield this.status.checkStatus(args.credential, didDoc);
      if (!(0, utils_1.isDefined)(statusCheck.revoked)) {
        throw new Error(`invalid_result: 'revoked' property missing. The Credential Status verification resulted in an ambiguous result: ${JSON.stringify(statusCheck)}`);
      }
      return statusCheck;
    });
  }
}
exports.CredentialStatusPlugin = CredentialStatusPlugin;
