"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DidUniConstants = exports.DIDRegistrationRequestBuilder = exports.UniRegistrar = exports.getUniResolvers = exports.getUniResolver = exports.UniResolver = void 0;
const DIDRegistrationRequestBuilder_1 = require("./registrar/DIDRegistrationRequestBuilder");
Object.defineProperty(exports, "DIDRegistrationRequestBuilder", {
  enumerable: true,
  get: function () {
    return DIDRegistrationRequestBuilder_1.DIDRegistrationRequestBuilder;
  }
});
const UniRegistrar_1 = require("./registrar/UniRegistrar");
Object.defineProperty(exports, "UniRegistrar", {
  enumerable: true,
  get: function () {
    return UniRegistrar_1.UniRegistrar;
  }
});
const UniResolver_1 = require("./resolver/UniResolver");
Object.defineProperty(exports, "getUniResolver", {
  enumerable: true,
  get: function () {
    return UniResolver_1.getUniResolver;
  }
});
Object.defineProperty(exports, "getUniResolvers", {
  enumerable: true,
  get: function () {
    return UniResolver_1.getUniResolvers;
  }
});
Object.defineProperty(exports, "UniResolver", {
  enumerable: true,
  get: function () {
    return UniResolver_1.UniResolver;
  }
});
const constants_1 = require("./types/constants");
Object.defineProperty(exports, "DidUniConstants", {
  enumerable: true,
  get: function () {
    return constants_1.Constants;
  }
});
