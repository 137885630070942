"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SuppliedSigner = void 0;
function SuppliedSigner(keyRef, context, algorithm) {
  return data => __awaiter(this, void 0, void 0, function* () {
    const input = data instanceof Object.getPrototypeOf(Uint8Array) ? new TextDecoder().decode(data) : data;
    return yield context.agent.keyManagerSign({
      keyRef: keyRef.kid,
      algorithm,
      data: input
    });
  });
}
exports.SuppliedSigner = SuppliedSigner;
