"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SuppliedSigner = exports.KeyAlgo = void 0;
var SuppliedSigner_1 = require("./SuppliedSigner");
Object.defineProperty(exports, "KeyAlgo", {
  enumerable: true,
  get: function () {
    return SuppliedSigner_1.KeyAlgo;
  }
});
Object.defineProperty(exports, "SuppliedSigner", {
  enumerable: true,
  get: function () {
    return SuppliedSigner_1.SuppliedSigner;
  }
});
