"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.decodeSdJwtVc = void 0;
const types_1 = require("@sd-jwt/types");
const utils_1 = require("@sd-jwt/utils");
const fromCompact_1 = require("./fromCompact");
const utils_2 = require("@sd-jwt/utils");
const calculateDigest_1 = require("../disclosures/calculateDigest");
const disclosures_1 = require("../disclosures");
const decodeSdJwtVc = (compact, hasher) => {
  var _a, _b;
  const {
    header,
    payload,
    signature,
    keyBinding,
    disclosures
  } = (0, fromCompact_1.sdJwtVcFromCompact)(compact);
  const hasherAlgorithm = (_a = (0, utils_2.getValueByKeyAnyLevel)(payload, '_sd_alg')) !== null && _a !== void 0 ? _a : types_1.HasherAlgorithm.Sha256;
  const disclosuresWithDigestsResult = (_b = disclosures === null || disclosures === void 0 ? void 0 : disclosures.map(disclosure => {
    const digestResult = (0, calculateDigest_1.disclosureCalculateDigest)(disclosure, hasherAlgorithm, hasher);
    return (0, utils_1.isPromise)(digestResult) ? digestResult.then(digest => Object.assign(Object.assign({}, disclosure), {
      digest
    })) : Object.assign(Object.assign({}, disclosure), {
      digest: digestResult
    });
  })) !== null && _b !== void 0 ? _b : [];
  const basePayload = {
    compactSdJwtVc: compact,
    signedPayload: payload,
    header,
    signature,
    keyBinding
  };
  if (isAsyncCalculateDigestReturnType(disclosuresWithDigestsResult)) {
    return Promise.all(disclosuresWithDigestsResult).then(disclosureWithDigests => Object.assign(Object.assign({}, basePayload), {
      disclosures: disclosureWithDigests,
      decodedPayload: (0, disclosures_1.decodeDisclosuresInPayload)(basePayload.signedPayload, disclosureWithDigests)
    }));
  } else {
    return Object.assign(Object.assign({}, basePayload), {
      disclosures: disclosuresWithDigestsResult,
      decodedPayload: (0, disclosures_1.decodeDisclosuresInPayload)(basePayload.signedPayload, disclosuresWithDigestsResult)
    });
  }
};
exports.decodeSdJwtVc = decodeSdJwtVc;
function isAsyncCalculateDigestReturnType(disclosureWithDigests) {
  return (0, utils_1.isPromise)(disclosureWithDigests[0]);
}
