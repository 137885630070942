"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.disclosureCalculateDigest = void 0;
const utils_1 = require("@sd-jwt/utils");
const toArray_1 = require("./toArray");
const disclosureCalculateDigest = (disclosure, algorithm, hasher) => {
  const encoded = utils_1.Base64url.encodeFromJson((0, toArray_1.disclosureToArray)(disclosure));
  const digest = hasher(encoded, algorithm);
  return (0, utils_1.isPromise)(digest) ? digest.then(utils_1.Base64url.encode) : utils_1.Base64url.encode(digest);
};
exports.disclosureCalculateDigest = disclosureCalculateDigest;
