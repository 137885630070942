"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.disclosureFromString = void 0;
const utils_1 = require("@sd-jwt/utils");
const fromArray_1 = require("./fromArray");
const disclosureFromString = s => {
  const item = utils_1.Base64url.decodeToJson(s);
  return Object.assign(Object.assign({}, (0, fromArray_1.disclosureFromArray)(item)), {
    encoded: s
  });
};
exports.disclosureFromString = disclosureFromString;
