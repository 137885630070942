'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DIDRegistrationRequestBuilder = void 0;
const DIDRegistrationRequest_1 = require("./DIDRegistrationRequest");
class DIDRegistrationRequestBuilder extends DIDRegistrationRequest_1.DIDRegistrationRequest {
  withJobId(jobId) {
    return Object.assign(this, {
      jobId: jobId
    });
  }
  withDidDocument(didDocument) {
    return Object.assign(this, {
      didDocument: didDocument
    });
  }
  withOptions(options) {
    return Object.assign(this, {
      options: options
    });
  }
  withSecret(secret) {
    return Object.assign(this, {
      secret: secret
    });
  }
  build() {
    return new DIDRegistrationRequest_1.DIDRegistrationRequest(this);
  }
}
exports.DIDRegistrationRequestBuilder = DIDRegistrationRequestBuilder;
