"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.flattenMigrations = exports.flattenArray = void 0;
const flattenArray = args => args.items.flat();
exports.flattenArray = flattenArray;
const flattenMigrations = args => args.migrations.flat();
exports.flattenMigrations = flattenMigrations;
