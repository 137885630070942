"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCredentialByIdOrHash = void 0;
function getCredentialByIdOrHash(context, idOrHash) {
  return __awaiter(this, void 0, void 0, function* () {
    let vc;
    let hash;
    const uniqueVCs = yield context.agent.dataStoreORMGetVerifiableCredentials({
      where: [{
        column: 'id',
        value: [idOrHash],
        op: 'Equal'
      }]
    });
    if (uniqueVCs.length === 0) {
      hash = idOrHash;
      vc = yield context.agent.dataStoreGetVerifiableCredential({
        hash
      });
    } else {
      const uniqueVC = uniqueVCs[0];
      hash = uniqueVC.hash;
      vc = uniqueVC.verifiableCredential;
    }
    return {
      vc,
      id: idOrHash,
      hash
    };
  });
}
exports.getCredentialByIdOrHash = getCredentialByIdOrHash;
