import {
  CredentialStatus,
  ICredentialStatus,
  ICredentialVerifier,
  TAgent,
  VerifiableCredential,
} from '@veramo/core';
import {
  StatusList2021EntryCredentialStatus,
  fetchStatusListCredential,
  checkStatusIndexFromStatusListCredential,
} from '@sphereon/ssi-sdk.vc-status-list';
import { CredentialJwtOrJSON } from 'credential-status';
import { CredentialStatusPlugin } from '@veramo/credential-status';

export type AgentCoreType = ICredentialStatus & ICredentialVerifier;

export class AgentCore {
  protected agent!: TAgent<AgentCoreType>;
  protected plugins: CredentialStatusPlugin[];

  constructor() {
    this.plugins = [
      new CredentialStatusPlugin({
        StatusList2021Entry: this.validateStatusList2021.bind(this),
      }),
    ];
  }

  protected async validateStatusList2021(
    credential: CredentialJwtOrJSON
  ): Promise<CredentialStatus> {
    const status = (credential as VerifiableCredential)
      .credentialStatus as StatusList2021EntryCredentialStatus;
    try {
      const statusListCredential = await fetchStatusListCredential(status);
      return this.agent
        .verifyCredential({
          credential: statusListCredential as VerifiableCredential,
        })
        .then((res) => {
          if (res.error)
            throw new Error(
              `something is wrong with the statuslist credential: ${res.error}`
            );
          return checkStatusIndexFromStatusListCredential({
            ...status,
            statusListCredential,
          }).then((revoked) => ({
            revoked,
          }));
        });
    } catch (error) {
      throw new Error(
        `unable to validate the statuslist credential for ${status.id}`
      );
    }
  }
}
