"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.statusList2021ToVerifiableCredential = exports.updateStatusListIndexFromEncodedList = exports.statusListCredentialToDetails = exports.updateStatusIndexFromStatusListCredential = exports.createNewStatusList = exports.checkStatusIndexFromStatusListCredential = exports.simpleCheckStatusFromStatusListUrl = exports.checkStatusForCredential = exports.vcLibCheckStatusFunction = exports.statusPluginStatusFunction = exports.fetchStatusListCredential = void 0;
const ssi_sdk_ext_did_utils_1 = require("@sphereon/ssi-sdk-ext.did-utils");
const ssi_types_1 = require("@sphereon/ssi-types");
const vc_status_list_1 = require("@sphereon/vc-status-list");
function fetchStatusListCredential(args) {
  return __awaiter(this, void 0, void 0, function* () {
    const url = getAssertedValue('statusListCredential', args.statusListCredential);
    try {
      const response = yield fetch(url);
      if (!response.ok) {
        const error = `Fetching status list ${url} resulted in an error: ${response.status} : ${response.statusText}`;
        throw Error(error);
      }
      const responseAsText = yield response.text();
      if (responseAsText.trim().startsWith('{')) {
        return JSON.parse(responseAsText);
      }
      return responseAsText;
    } catch (error) {
      console.log(`Fetching status list ${url} resulted in an unexpected error: ${error instanceof Error ? error.message : JSON.stringify(error)}`);
      throw error;
    }
  });
}
exports.fetchStatusListCredential = fetchStatusListCredential;
function statusPluginStatusFunction(args) {
  return (credential, didDoc) => __awaiter(this, void 0, void 0, function* () {
    const result = yield checkStatusForCredential(Object.assign(Object.assign({}, args), {
      documentLoader: args.documentLoader,
      credential: credential,
      errorUnknownListType: args.errorUnknownListType
    }));
    return Object.assign({
      revoked: !result.verified || result.error
    }, result.error && {
      error: result.error
    });
  });
}
exports.statusPluginStatusFunction = statusPluginStatusFunction;
/**
 * Function that can be used together with @digitalbazar/vc and @digitialcredentials/vc
 * @param args
 */
function vcLibCheckStatusFunction(args) {
  const {
    mandatoryCredentialStatus,
    verifyStatusListCredential,
    verifyMatchingIssuers,
    errorUnknownListType
  } = args;
  return args => {
    return checkStatusForCredential(Object.assign(Object.assign({}, args), {
      mandatoryCredentialStatus,
      verifyStatusListCredential,
      verifyMatchingIssuers,
      errorUnknownListType
    }));
  };
}
exports.vcLibCheckStatusFunction = vcLibCheckStatusFunction;
function checkStatusForCredential(args) {
  var _a, _b;
  return __awaiter(this, void 0, void 0, function* () {
    const verifyStatusListCredential = (_a = args.verifyStatusListCredential) !== null && _a !== void 0 ? _a : true;
    const verifyMatchingIssuers = (_b = args.verifyMatchingIssuers) !== null && _b !== void 0 ? _b : true;
    const uniform = ssi_types_1.CredentialMapper.toUniformCredential(args.credential);
    if (!('credentialStatus' in uniform) || !uniform.credentialStatus) {
      if (args.mandatoryCredentialStatus) {
        const error = 'No credential status object found in the Verifiable Credential and it is mandatory';
        console.log(error);
        return {
          verified: false,
          error
        };
      }
      return {
        verified: true
      };
    }
    if ('credentialStatus' in uniform && uniform.credentialStatus) {
      if (uniform.credentialStatus.type === 'StatusList2021Entry') {
        return (0, vc_status_list_1.checkStatus)(Object.assign(Object.assign({}, args), {
          verifyStatusListCredential,
          verifyMatchingIssuers
        }));
      } else if (args === null || args === void 0 ? void 0 : args.errorUnknownListType) {
        const error = `Credential status type ${uniform.credentialStatus.type} is not supported, and check status has been configured to not allow for that`;
        console.log(error);
        return {
          verified: false,
          error
        };
      } else {
        console.log(`Skipped verification of status type ${uniform.credentialStatus.type} as we do not support it (yet)`);
      }
    }
    return {
      verified: true
    };
  });
}
exports.checkStatusForCredential = checkStatusForCredential;
function simpleCheckStatusFromStatusListUrl(args) {
  return __awaiter(this, void 0, void 0, function* () {
    return checkStatusIndexFromStatusListCredential(Object.assign(Object.assign({}, args), {
      statusListCredential: yield fetchStatusListCredential(args)
    }));
  });
}
exports.simpleCheckStatusFromStatusListUrl = simpleCheckStatusFromStatusListUrl;
function checkStatusIndexFromStatusListCredential(args) {
  var _a;
  return __awaiter(this, void 0, void 0, function* () {
    const requestedType = getAssertedStatusListType((_a = args.type) === null || _a === void 0 ? void 0 : _a.replace('Entry', ''));
    const uniform = ssi_types_1.CredentialMapper.toUniformCredential(args.statusListCredential);
    const {
      issuer,
      type,
      credentialSubject,
      id
    } = uniform;
    getAssertedValue('issuer', issuer); // We are only checking the value here
    getAssertedValue('credentialSubject', credentialSubject);
    if (args.statusPurpose && 'statusPurpose' in credentialSubject) {
      if (args.statusPurpose !== credentialSubject.statusPurpose) {
        throw Error(`Status purpose in StatusList credential with id ${id} and value ${credentialSubject.statusPurpose} does not match supplied purpose: ${args.statusPurpose}`);
      }
    } else if (args.id && args.id !== id) {
      throw Error(`Status list id ${id} did not match required supplied id: ${args.id}`);
    }
    if (!type || !(type.includes(requestedType) || type.includes(requestedType + 'Credential'))) {
      throw Error(`Credential type ${JSON.stringify(type)} does not contain requested type ${requestedType}`);
    }
    // @ts-ignore
    const encodedList = getAssertedValue('encodedList', credentialSubject['encodedList']);
    const statusList = yield vc_status_list_1.StatusList.decode({
      encodedList
    });
    const status = statusList.getStatus(typeof args.statusListIndex === 'number' ? args.statusListIndex : Number.parseInt(args.statusListIndex));
    return status;
  });
}
exports.checkStatusIndexFromStatusListCredential = checkStatusIndexFromStatusListCredential;
function createNewStatusList(args, context) {
  var _a, _b, _c;
  return __awaiter(this, void 0, void 0, function* () {
    const length = (_a = args === null || args === void 0 ? void 0 : args.length) !== null && _a !== void 0 ? _a : 250000;
    const proofFormat = (_b = args === null || args === void 0 ? void 0 : args.proofFormat) !== null && _b !== void 0 ? _b : 'lds';
    const {
      issuer,
      type,
      id
    } = getAssertedValues(args);
    const correlationId = getAssertedValue('correlationId', args.correlationId);
    const list = new vc_status_list_1.StatusList({
      length
    });
    const encodedList = yield list.encode();
    const statusPurpose = (_c = args.statusPurpose) !== null && _c !== void 0 ? _c : 'revocation';
    const statusListCredential = yield statusList2021ToVerifiableCredential(Object.assign(Object.assign({}, args), {
      type,
      proofFormat,
      encodedList
    }), context);
    return {
      encodedList,
      statusListCredential,
      length,
      type,
      proofFormat,
      id,
      correlationId,
      issuer,
      statusPurpose,
      indexingDirection: 'rightToLeft'
    };
  });
}
exports.createNewStatusList = createNewStatusList;
function updateStatusIndexFromStatusListCredential(args, context) {
  return __awaiter(this, void 0, void 0, function* () {
    return updateStatusListIndexFromEncodedList(Object.assign(Object.assign({}, yield statusListCredentialToDetails(args)), {
      statusListIndex: args.statusListIndex,
      value: args.value
    }), context);
  });
}
exports.updateStatusIndexFromStatusListCredential = updateStatusIndexFromStatusListCredential;
function statusListCredentialToDetails(args) {
  return __awaiter(this, void 0, void 0, function* () {
    const credential = getAssertedValue('statusListCredential', args.statusListCredential);
    const uniform = ssi_types_1.CredentialMapper.toUniformCredential(credential);
    const {
      issuer,
      type,
      credentialSubject
    } = uniform;
    if (!type.includes('StatusList2021Credential')) {
      throw Error('StatusList2021Credential type should be present in the Verifiable Credential');
    }
    const id = getAssertedValue('id', uniform.id);
    // @ts-ignore
    const {
      encodedList,
      statusPurpose
    } = credentialSubject;
    const proofFormat = ssi_types_1.CredentialMapper.detectDocumentType(credential) === 0 /* DocumentFormat.JWT */ ? 'jwt' : 'lds';
    return Object.assign(Object.assign({
      id,
      encodedList,
      issuer,
      type: ssi_types_1.StatusListType.StatusList2021,
      proofFormat,
      indexingDirection: 'rightToLeft',
      length: (yield vc_status_list_1.StatusList.decode({
        encodedList
      })).length,
      statusPurpose,
      statusListCredential: credential
    }, args.correlationId && {
      correlationId: args.correlationId
    }), args.driverType && {
      driverType: args.driverType
    });
  });
}
exports.statusListCredentialToDetails = statusListCredentialToDetails;
function updateStatusListIndexFromEncodedList(args, context) {
  var _a;
  return __awaiter(this, void 0, void 0, function* () {
    const {
      issuer,
      type,
      id
    } = getAssertedValues(args);
    const proofFormat = (_a = args === null || args === void 0 ? void 0 : args.proofFormat) !== null && _a !== void 0 ? _a : 'lds';
    const origEncodedList = getAssertedValue('encodedList', args.encodedList);
    const index = getAssertedValue('index', typeof args.statusListIndex === 'number' ? args.statusListIndex : Number.parseInt(args.statusListIndex));
    const value = getAssertedValue('value', args.value);
    const statusPurpose = getAssertedValue('statusPurpose', args.statusPurpose);
    const statusList = yield vc_status_list_1.StatusList.decode({
      encodedList: origEncodedList
    });
    statusList.setStatus(index, value);
    const encodedList = yield statusList.encode();
    const statusListCredential = yield statusList2021ToVerifiableCredential(Object.assign(Object.assign({}, args), {
      type,
      proofFormat,
      encodedList
    }), context);
    return {
      encodedList,
      statusListCredential,
      length: statusList.length - 1,
      type,
      proofFormat,
      id,
      issuer,
      statusPurpose,
      indexingDirection: 'rightToLeft'
    };
  });
}
exports.updateStatusListIndexFromEncodedList = updateStatusListIndexFromEncodedList;
function statusList2021ToVerifiableCredential(args, context) {
  var _a;
  return __awaiter(this, void 0, void 0, function* () {
    const {
      issuer,
      id,
      type
    } = getAssertedValues(args);
    const identifier = yield (0, ssi_sdk_ext_did_utils_1.getIdentifier)({
      identifier: typeof issuer === 'string' ? issuer : issuer.id
    }, context);
    const key = yield (0, ssi_sdk_ext_did_utils_1.getKey)(identifier, 'assertionMethod', context, args.keyRef);
    const keyRef = key.kid;
    const encodedList = getAssertedValue('encodedList', args.encodedList);
    const statusPurpose = getAssertedValue('statusPurpose', args.statusPurpose);
    const credential = {
      '@context': ['https://www.w3.org/2018/credentials/v1', 'https://w3id.org/vc/status-list/2021/v1'],
      id,
      issuer,
      // issuanceDate: "2021-03-10T04:24:12.164Z",
      type: ['VerifiableCredential', `${type}Credential`],
      credentialSubject: {
        id,
        type,
        statusPurpose,
        encodedList
      }
    };
    // TODO copy statuslist schema to local and disable fetching remote contexts
    const verifiableCredential = yield context.agent.createVerifiableCredential({
      credential,
      keyRef,
      proofFormat: (_a = args.proofFormat) !== null && _a !== void 0 ? _a : 'lds',
      fetchRemoteContexts: true
    });
    return ssi_types_1.CredentialMapper.toWrappedVerifiableCredential(verifiableCredential).original;
  });
}
exports.statusList2021ToVerifiableCredential = statusList2021ToVerifiableCredential;
function getAssertedStatusListType(type) {
  const assertedType = type !== null && type !== void 0 ? type : ssi_types_1.StatusListType.StatusList2021;
  if (assertedType !== ssi_types_1.StatusListType.StatusList2021) {
    throw Error(`StatusList type ${assertedType} is not supported (yet)`);
  }
  return assertedType;
}
function getAssertedValue(name, value) {
  if (value === undefined || value === null) {
    throw Error(`Missing required ${name} value`);
  }
  return value;
}
function getAssertedValues(args) {
  const type = getAssertedStatusListType(args === null || args === void 0 ? void 0 : args.type);
  const id = getAssertedValue('id', args.id);
  const issuer = getAssertedValue('issuer', args.issuer);
  return {
    id,
    issuer,
    type
  };
}
