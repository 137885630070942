"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.base64ToBase64URL = exports.Base64url = void 0;
const buffer_1 = require("buffer");
class Base64url {
  /**
   *
   * Encode into base64url string
   *
   */
  static encode(input) {
    return base64ToBase64URL(buffer_1.Buffer.from(input).toString('base64'));
  }
  /**
   *
   * Encode from JSON into a base64url string
   *
   */
  static encodeFromJson(input) {
    return base64ToBase64URL(buffer_1.Buffer.from(JSON.stringify(input)).toString('base64'));
  }
  /**
   *
   * Decode from base64url into JSON
   *
   */
  static decodeToJson(input) {
    return JSON.parse(buffer_1.Buffer.from(input, 'base64').toString());
  }
  /**
   *
   * Decode from base64url into a byte array
   *
   */
  static decode(input) {
    return Uint8Array.from(buffer_1.Buffer.from(input, 'base64'));
  }
}
exports.Base64url = Base64url;
function base64ToBase64URL(base64) {
  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
}
exports.base64ToBase64URL = base64ToBase64URL;
