'use strict';

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UniRegistrar = void 0;
const did_resolver_1 = require("did-resolver");
const constants_1 = require("../types/constants");
const fetch = require('cross-fetch');
/**
 * Class for performing various DID registration operations.
 */
class UniRegistrar {
  /** Registrar constructor */
  constructor(config) {
    this.config = Object.assign(Object.assign({}, constants_1.DefaultConfig), config);
  }
  /**
   * Sets the base URL for the registrar.
   *
   * @param url The base URL for the registrar.
   * @return this.
   */
  setBaseURL(url) {
    this.config.createURL = UniRegistrar.setConfigUrl(this.config.createURL, url);
    this.config.updateURL = UniRegistrar.setConfigUrl(this.config.updateURL, url);
    this.config.deactivateURL = UniRegistrar.setConfigUrl(this.config.deactivateURL, url);
    return this;
  }
  /**
   * Sets the create URL for the registrar.
   *
   * @param url The create URL for the registrar.
   * @return this.
   */
  setCreateURL(url) {
    this.config.createURL = url;
    return this;
  }
  /**
   * Gets the config containing the URLs.
   * @return Config The config.
   */
  getConfig() {
    return this.config;
  }
  /**
   * Sets the update URL for the registrar.
   *
   * @param url The update URL for the registrar.
   * @return this.
   */
  setUpdateURL(url) {
    this.config.updateURL = url;
    return this;
  }
  /**
   * Sets the deactivate URL for the registrar.
   *
   * @param url The deactivate URL for the registrar.
   * @return this.
   */
  setDeactivateURL(url) {
    this.config.deactivateURL = url;
    return this;
  }
  /**
   * Creates a identity for a specific method.
   *
   * @param method The requested DID method for the operation.
   * @param request Request matching the method needed for creating the identity.
   * @return job result.
   */
  create(method, request) {
    return __awaiter(this, void 0, void 0, function* () {
      return executePost(this.config.createURL, request, {
        method
      });
    });
  }
  /**
   * Updates a identity for a specific method.
   *
   * @param did The identifier (did).
   * @param request Request matching the method needed for updating the identity.
   * @return {didResolutionMetadata: {error: string}}, job result.
   */
  update(did, request) {
    return __awaiter(this, void 0, void 0, function* () {
      return executePost(this.config.updateURL, request, {
        did
      });
    });
  }
  /**
   * Deactivates a identity for a specific method.
   *
   * @param did The identifier (did).
   * @param request Request matching the method needed for deactivating the identity.
   * @return {didResolutionMetadata: {error: string}}, job result.
   */
  deactivate(did, request) {
    return __awaiter(this, void 0, void 0, function* () {
      return executePost(this.config.deactivateURL, request, {
        did
      });
    });
  }
  static setConfigUrl(configVal, url) {
    const path = constants_1.Constants.URL_PATHNAME_REGEX.exec(configVal);
    if (path && path.length > 1) {
      configVal = `${url}${path[1]}`;
    }
    return configVal;
  }
}
exports.UniRegistrar = UniRegistrar;
/**
 * Creates a URL with method query parameter.
 *
 * @param url The URL.
 * @param method The did method.
 * @return string URL with query parameter.
 */
function createURL(url, method) {
  return `${url}?method=${method}`;
}
function executePost(baseUrl, request, opts) {
  return __awaiter(this, void 0, void 0, function* () {
    let didMethod = opts === null || opts === void 0 ? void 0 : opts.method;
    const identifier = opts === null || opts === void 0 ? void 0 : opts.did;
    if (identifier) {
      const parsedDid = (0, did_resolver_1.parse)(identifier);
      if (parsedDid === null) {
        return new Promise(resolve => resolve({
          didState: {
            state: constants_1.Constants.INVALID_DID
          }
        }));
      }
      didMethod = parsedDid.method;
    }
    if (!didMethod) {
      throw new Error('No DID method passed or deducted');
    }
    const url = createURL(baseUrl, didMethod);
    return fetch(url, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(Object.assign({
        identifier
      }, request))
    }).then(response => __awaiter(this, void 0, void 0, function* () {
      if (response.status >= 400) {
        throw new Error(yield response.text());
      } else {
        return response.json();
      }
    }));
  });
}
