"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { Logger } from "@ethersproject/logger";
import { version } from "./_version";
const logger = new Logger(version);
export function defineReadOnly(object, name, value) {
  Object.defineProperty(object, name, {
    enumerable: true,
    value: value,
    writable: false
  });
}
// Crawl up the constructor chain to find a static method
export function getStatic(ctor, key) {
  for (let i = 0; i < 32; i++) {
    if (ctor[key]) {
      return ctor[key];
    }
    if (!ctor.prototype || typeof ctor.prototype !== "object") {
      break;
    }
    ctor = Object.getPrototypeOf(ctor.prototype).constructor;
  }
  return null;
}
export function resolveProperties(object) {
  return __awaiter(this, void 0, void 0, function* () {
    const promises = Object.keys(object).map(key => {
      const value = object[key];
      return Promise.resolve(value).then(v => ({
        key: key,
        value: v
      }));
    });
    const results = yield Promise.all(promises);
    return results.reduce((accum, result) => {
      accum[result.key] = result.value;
      return accum;
    }, {});
  });
}
export function checkProperties(object, properties) {
  if (!object || typeof object !== "object") {
    logger.throwArgumentError("invalid object", "object", object);
  }
  Object.keys(object).forEach(key => {
    if (!properties[key]) {
      logger.throwArgumentError("invalid object key - " + key, "transaction:" + key, object);
    }
  });
}
export function shallowCopy(object) {
  const result = {};
  for (const key in object) {
    result[key] = object[key];
  }
  return result;
}
const opaque = {
  bigint: true,
  boolean: true,
  "function": true,
  number: true,
  string: true
};
function _isFrozen(object) {
  // Opaque objects are not mutable, so safe to copy by assignment
  if (object === undefined || object === null || opaque[typeof object]) {
    return true;
  }
  if (Array.isArray(object) || typeof object === "object") {
    if (!Object.isFrozen(object)) {
      return false;
    }
    const keys = Object.keys(object);
    for (let i = 0; i < keys.length; i++) {
      let value = null;
      try {
        value = object[keys[i]];
      } catch (error) {
        // If accessing a value triggers an error, it is a getter
        // designed to do so (e.g. Result) and is therefore "frozen"
        continue;
      }
      if (!_isFrozen(value)) {
        return false;
      }
    }
    return true;
  }
  return logger.throwArgumentError(`Cannot deepCopy ${typeof object}`, "object", object);
}
// Returns a new copy of object, such that no properties may be replaced.
// New properties may be added only to objects.
function _deepCopy(object) {
  if (_isFrozen(object)) {
    return object;
  }
  // Arrays are mutable, so we need to create a copy
  if (Array.isArray(object)) {
    return Object.freeze(object.map(item => deepCopy(item)));
  }
  if (typeof object === "object") {
    const result = {};
    for (const key in object) {
      const value = object[key];
      if (value === undefined) {
        continue;
      }
      defineReadOnly(result, key, deepCopy(value));
    }
    return result;
  }
  return logger.throwArgumentError(`Cannot deepCopy ${typeof object}`, "object", object);
}
export function deepCopy(object) {
  return _deepCopy(object);
}
export class Description {
  constructor(info) {
    for (const key in info) {
      this[key] = deepCopy(info[key]);
    }
  }
}
