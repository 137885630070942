"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CredentialStatusPlugin = void 0;
/* istanbul ignore file */
var credential_status_1 = require("./credential-status");
Object.defineProperty(exports, "CredentialStatusPlugin", {
  enumerable: true,
  get: function () {
    return credential_status_1.CredentialStatusPlugin;
  }
});
