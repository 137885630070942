"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.disclosureFromArray = void 0;
const disclosureFromArray = a => a[2] ? {
  salt: a[0],
  key: a[1],
  value: a[2]
} : {
  salt: a[0],
  value: a[1]
};
exports.disclosureFromArray = disclosureFromArray;
