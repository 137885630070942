function unwrapExports(x) {
  return x && x.__esModule && Object.prototype.hasOwnProperty.call(x, 'default') ? x['default'] : x;
}
function createCommonjsModule(fn, module) {
  return module = {
    exports: {}
  }, fn(module, module.exports), module.exports;
}
var context = createCommonjsModule(function (module, exports) {
  /*! For license information please see context.js.LICENSE.txt */
  (() => {
    var e = {
        717: e => {
          e.exports = {
            CONTEXT_FILENAME: "credentials-v1.jsonld",
            CONTEXT_URL: "https://www.w3.org/2018/credentials/v1",
            CREDENTIALS_CONTEXT_V1_URL: "https://www.w3.org/2018/credentials/v1"
          };
        },
        869: e => {
          e.exports = {
            "@context": {
              "@version": 1.1,
              "@protected": !0,
              id: "@id",
              type: "@type",
              VerifiableCredential: {
                "@id": "https://www.w3.org/2018/credentials#VerifiableCredential",
                "@context": {
                  "@version": 1.1,
                  "@protected": !0,
                  id: "@id",
                  type: "@type",
                  cred: "https://www.w3.org/2018/credentials#",
                  sec: "https://w3id.org/security#",
                  xsd: "http://www.w3.org/2001/XMLSchema#",
                  credentialSchema: {
                    "@id": "cred:credentialSchema",
                    "@type": "@id",
                    "@context": {
                      "@version": 1.1,
                      "@protected": !0,
                      id: "@id",
                      type: "@type",
                      cred: "https://www.w3.org/2018/credentials#",
                      JsonSchemaValidator2018: "cred:JsonSchemaValidator2018"
                    }
                  },
                  credentialStatus: {
                    "@id": "cred:credentialStatus",
                    "@type": "@id"
                  },
                  credentialSubject: {
                    "@id": "cred:credentialSubject",
                    "@type": "@id"
                  },
                  evidence: {
                    "@id": "cred:evidence",
                    "@type": "@id"
                  },
                  expirationDate: {
                    "@id": "cred:expirationDate",
                    "@type": "xsd:dateTime"
                  },
                  holder: {
                    "@id": "cred:holder",
                    "@type": "@id"
                  },
                  issued: {
                    "@id": "cred:issued",
                    "@type": "xsd:dateTime"
                  },
                  issuer: {
                    "@id": "cred:issuer",
                    "@type": "@id"
                  },
                  issuanceDate: {
                    "@id": "cred:issuanceDate",
                    "@type": "xsd:dateTime"
                  },
                  proof: {
                    "@id": "sec:proof",
                    "@type": "@id",
                    "@container": "@graph"
                  },
                  refreshService: {
                    "@id": "cred:refreshService",
                    "@type": "@id",
                    "@context": {
                      "@version": 1.1,
                      "@protected": !0,
                      id: "@id",
                      type: "@type",
                      cred: "https://www.w3.org/2018/credentials#",
                      ManualRefreshService2018: "cred:ManualRefreshService2018"
                    }
                  },
                  termsOfUse: {
                    "@id": "cred:termsOfUse",
                    "@type": "@id"
                  },
                  validFrom: {
                    "@id": "cred:validFrom",
                    "@type": "xsd:dateTime"
                  },
                  validUntil: {
                    "@id": "cred:validUntil",
                    "@type": "xsd:dateTime"
                  }
                }
              },
              VerifiablePresentation: {
                "@id": "https://www.w3.org/2018/credentials#VerifiablePresentation",
                "@context": {
                  "@version": 1.1,
                  "@protected": !0,
                  id: "@id",
                  type: "@type",
                  cred: "https://www.w3.org/2018/credentials#",
                  sec: "https://w3id.org/security#",
                  holder: {
                    "@id": "cred:holder",
                    "@type": "@id"
                  },
                  proof: {
                    "@id": "sec:proof",
                    "@type": "@id",
                    "@container": "@graph"
                  },
                  verifiableCredential: {
                    "@id": "cred:verifiableCredential",
                    "@type": "@id",
                    "@container": "@graph"
                  }
                }
              },
              EcdsaSecp256k1Signature2019: {
                "@id": "https://w3id.org/security#EcdsaSecp256k1Signature2019",
                "@context": {
                  "@version": 1.1,
                  "@protected": !0,
                  id: "@id",
                  type: "@type",
                  sec: "https://w3id.org/security#",
                  xsd: "http://www.w3.org/2001/XMLSchema#",
                  challenge: "sec:challenge",
                  created: {
                    "@id": "http://purl.org/dc/terms/created",
                    "@type": "xsd:dateTime"
                  },
                  domain: "sec:domain",
                  expires: {
                    "@id": "sec:expiration",
                    "@type": "xsd:dateTime"
                  },
                  jws: "sec:jws",
                  nonce: "sec:nonce",
                  proofPurpose: {
                    "@id": "sec:proofPurpose",
                    "@type": "@vocab",
                    "@context": {
                      "@version": 1.1,
                      "@protected": !0,
                      id: "@id",
                      type: "@type",
                      sec: "https://w3id.org/security#",
                      assertionMethod: {
                        "@id": "sec:assertionMethod",
                        "@type": "@id",
                        "@container": "@set"
                      },
                      authentication: {
                        "@id": "sec:authenticationMethod",
                        "@type": "@id",
                        "@container": "@set"
                      }
                    }
                  },
                  proofValue: "sec:proofValue",
                  verificationMethod: {
                    "@id": "sec:verificationMethod",
                    "@type": "@id"
                  }
                }
              },
              EcdsaSecp256r1Signature2019: {
                "@id": "https://w3id.org/security#EcdsaSecp256r1Signature2019",
                "@context": {
                  "@version": 1.1,
                  "@protected": !0,
                  id: "@id",
                  type: "@type",
                  sec: "https://w3id.org/security#",
                  xsd: "http://www.w3.org/2001/XMLSchema#",
                  challenge: "sec:challenge",
                  created: {
                    "@id": "http://purl.org/dc/terms/created",
                    "@type": "xsd:dateTime"
                  },
                  domain: "sec:domain",
                  expires: {
                    "@id": "sec:expiration",
                    "@type": "xsd:dateTime"
                  },
                  jws: "sec:jws",
                  nonce: "sec:nonce",
                  proofPurpose: {
                    "@id": "sec:proofPurpose",
                    "@type": "@vocab",
                    "@context": {
                      "@version": 1.1,
                      "@protected": !0,
                      id: "@id",
                      type: "@type",
                      sec: "https://w3id.org/security#",
                      assertionMethod: {
                        "@id": "sec:assertionMethod",
                        "@type": "@id",
                        "@container": "@set"
                      },
                      authentication: {
                        "@id": "sec:authenticationMethod",
                        "@type": "@id",
                        "@container": "@set"
                      }
                    }
                  },
                  proofValue: "sec:proofValue",
                  verificationMethod: {
                    "@id": "sec:verificationMethod",
                    "@type": "@id"
                  }
                }
              },
              Ed25519Signature2018: {
                "@id": "https://w3id.org/security#Ed25519Signature2018",
                "@context": {
                  "@version": 1.1,
                  "@protected": !0,
                  id: "@id",
                  type: "@type",
                  sec: "https://w3id.org/security#",
                  xsd: "http://www.w3.org/2001/XMLSchema#",
                  challenge: "sec:challenge",
                  created: {
                    "@id": "http://purl.org/dc/terms/created",
                    "@type": "xsd:dateTime"
                  },
                  domain: "sec:domain",
                  expires: {
                    "@id": "sec:expiration",
                    "@type": "xsd:dateTime"
                  },
                  jws: "sec:jws",
                  nonce: "sec:nonce",
                  proofPurpose: {
                    "@id": "sec:proofPurpose",
                    "@type": "@vocab",
                    "@context": {
                      "@version": 1.1,
                      "@protected": !0,
                      id: "@id",
                      type: "@type",
                      sec: "https://w3id.org/security#",
                      assertionMethod: {
                        "@id": "sec:assertionMethod",
                        "@type": "@id",
                        "@container": "@set"
                      },
                      authentication: {
                        "@id": "sec:authenticationMethod",
                        "@type": "@id",
                        "@container": "@set"
                      }
                    }
                  },
                  proofValue: "sec:proofValue",
                  verificationMethod: {
                    "@id": "sec:verificationMethod",
                    "@type": "@id"
                  }
                }
              },
              RsaSignature2018: {
                "@id": "https://w3id.org/security#RsaSignature2018",
                "@context": {
                  "@version": 1.1,
                  "@protected": !0,
                  challenge: "sec:challenge",
                  created: {
                    "@id": "http://purl.org/dc/terms/created",
                    "@type": "xsd:dateTime"
                  },
                  domain: "sec:domain",
                  expires: {
                    "@id": "sec:expiration",
                    "@type": "xsd:dateTime"
                  },
                  jws: "sec:jws",
                  nonce: "sec:nonce",
                  proofPurpose: {
                    "@id": "sec:proofPurpose",
                    "@type": "@vocab",
                    "@context": {
                      "@version": 1.1,
                      "@protected": !0,
                      id: "@id",
                      type: "@type",
                      sec: "https://w3id.org/security#",
                      assertionMethod: {
                        "@id": "sec:assertionMethod",
                        "@type": "@id",
                        "@container": "@set"
                      },
                      authentication: {
                        "@id": "sec:authenticationMethod",
                        "@type": "@id",
                        "@container": "@set"
                      }
                    }
                  },
                  proofValue: "sec:proofValue",
                  verificationMethod: {
                    "@id": "sec:verificationMethod",
                    "@type": "@id"
                  }
                }
              },
              proof: {
                "@id": "https://w3id.org/security#proof",
                "@type": "@id",
                "@container": "@graph"
              }
            }
          };
        },
        858: (e, t, i) => {
          const {
              CONTEXT_URL: d
            } = i(717),
            r = i(869);
          e.exports = {
            documentLoader(e) {
              if (e !== d) throw new Error(`Loading document "${e}" is not allowed.`);
              return {
                contextUrl: null,
                document: r,
                documentUrl: e
              };
            }
          };
        },
        243: (e, t, i) => {
          const d = i(869),
            r = i(717),
            {
              documentLoader: o
            } = i(858),
            {
              CONTEXT_URL: c
            } = r,
            s = new Map();
          s.set(r.CONTEXT_URL, d), e.exports = {
            constants: r,
            contexts: s,
            documentLoader: o,
            CONTEXT_URL: c,
            CREDENTIALS_CONTEXT_V1_URL: c,
            CONTEXT_URL_V1: c,
            CONTEXT: d
          };
        }
      },
      t = {},
      i = function i(d) {
        var r = t[d];
        if (void 0 !== r) return r.exports;
        var o = t[d] = {
          exports: {}
        };
        return e[d](o, o.exports, i), o.exports;
      }(243),
      d = exports;
    for (var r in i) d[r] = i[r];
    i.__esModule && Object.defineProperty(d, "__esModule", {
      value: !0
    });
  })();
});
var context$1 = unwrapExports(context);
var context_1 = context.contexts;
var context_2 = context.constants;
var context_3 = context.CONTEXT;
var context_4 = context.CONTEXT_URL;
var context_5 = context.CONTEXT_URL_V1;
var context_6 = context.CREDENTIALS_CONTEXT_V1_URL;
export { context_3 as CONTEXT, context_4 as CONTEXT_URL, context_5 as CONTEXT_URL_V1, context_6 as CREDENTIALS_CONTEXT_V1_URL, context_2 as constants, context_1 as contexts, context$1 as default };