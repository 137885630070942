"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
function padString(input) {
  var segmentLength = 4;
  var stringLength = input.length;
  var diff = stringLength % segmentLength;
  if (!diff) {
    return input;
  }
  var position = stringLength;
  var padLength = segmentLength - diff;
  var paddedStringLength = stringLength + padLength;
  var buffer = Buffer.alloc(paddedStringLength);
  buffer.write(input);
  while (padLength--) {
    buffer.write("=", position++);
  }
  return buffer.toString();
}
exports.default = padString;